import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import Placeholder from '../assets/placeholder.png';
import AltaBank from '../assets/ads/altabank.jpg';
import Lexus from '../assets/ads/LHMiller-Lexus.jpg';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "usuo-digital-publications",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#usuo-digital-publications",
        "aria-label": "usuo digital publications permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`USUO Digital Publications`}</h1>
    <blockquote>
      <p parentName="blockquote">{`Please tap an image below to view the digital publications.`}</p>
    </blockquote>
    <section className="home-list">
      <a href="https://utahsymphony.millspub.com" alt="Link to Utah Symphony digital publications">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAIBAwUG/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQAC/9oADAMBAAIQAxAAAAHDiyslAa5m08uIdeN//8QAHhAAAQMEAwAAAAAAAAAAAAAAAQACEQMEEhMQIkH/2gAIAQEAAQUCDCW+JpgEzwzJajjCHVW9ajjqYV//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAgEAABAwQCAwAAAAAAAAAAAAABAAIREBIxQTJhcZGS/9oACAEBAAY/Ai4YCxTyuhSQpuZ9LVLXsEjcZXFvpf/EAB4QAAICAgIDAAAAAAAAAAAAAAERADEhUUFxYYGR/9oACAEBAAE/IRabZQE3o7h6UGYZCZhkHQNRRFAwkeXCB4vCOPv6hHJMpMiocAkCtg8C9J//2gAMAwEAAgADAAAAEKwIzP/EABcRAQEBAQAAAAAAAAAAAAAAAAEAERD/2gAIAQMBAT8QQSy05//EABcRAQEBAQAAAAAAAAAAAAAAAAABESH/2gAIAQIBAT8QlrXWv//EAB8QAQADAAIBBQAAAAAAAAAAAAEAESFBUZExcbHB8f/aAAgBAQABPxA+CF7L8RPSsaXCFsA6JoUod4jxBL3QZui38y0Nu8mpQHTM0p5ngT8hMcbVIvDNgXVWD3nLGUSArgvqf//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/232a0a96974a319d010dd4c91563665c/8ac56/utahsymphony.webp 240w", "/static/232a0a96974a319d010dd4c91563665c/d3be9/utahsymphony.webp 480w", "/static/232a0a96974a319d010dd4c91563665c/87ca7/utahsymphony.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/232a0a96974a319d010dd4c91563665c/09b79/utahsymphony.jpg 240w", "/static/232a0a96974a319d010dd4c91563665c/7cc5e/utahsymphony.jpg 480w", "/static/232a0a96974a319d010dd4c91563665c/1a144/utahsymphony.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/232a0a96974a319d010dd4c91563665c/1a144/utahsymphony.jpg",
                "alt": "Utah Symphony",
                "title": "Utah Symphony",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
        <div className="homeButtons">Utah Symphony</div>
      </a>
      <a href="https://utahopera.millspub.com" alt="Link to Utah Opera digital publications">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEAwH/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAcOULjXC8qPFGl3JA//EABwQAAICAwEBAAAAAAAAAAAAAAECAAMEERIUIv/aAAgBAQABBQLyiJjQ4yxlZyK3B3yLbOW9Gi+R9dI07rjOhn//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwEh/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEh/9oACAECAQE/AWqr/8QAHxAAAgECBwAAAAAAAAAAAAAAACEBMUERIkKBkbHh/9oACAEBAAY/AqjZUvsLHgaIdiookzdHhqP/xAAcEAADAQEBAAMAAAAAAAAAAAAAAREhMUFRceH/2gAIAQEAAT8hs839GukhRkFhHXzwXjKFAobvfwQtKG9a3z07IWk+i2bDjNYZqu0of//aAAwDAQACAAMAAAAQTMmB/8QAGBEBAQADAAAAAAAAAAAAAAAAABEBIYH/2gAIAQMBAT8Q40xSR//EABkRAAEFAAAAAAAAAAAAAAAAAAABEBEhcf/aAAgBAgEBPxBNZQgf/8QAIBABAAICAgIDAQAAAAAAAAAAAQARITFRYUFxgaGx8P/aAAgBAQABPxBSHwFt1nWZ0WImOPfUS7BwAxoLzFqv7jxPSdi+46Z5YaLfl5TdSXff7RLKMZqkPS2FoLKRsHRALr1V0cQLoCcCOTAWsLr4n//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/814a3eadcdc58a9da89bb0c790d37015/8ac56/utahopera.webp 240w", "/static/814a3eadcdc58a9da89bb0c790d37015/d3be9/utahopera.webp 480w", "/static/814a3eadcdc58a9da89bb0c790d37015/87ca7/utahopera.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/814a3eadcdc58a9da89bb0c790d37015/09b79/utahopera.jpg 240w", "/static/814a3eadcdc58a9da89bb0c790d37015/7cc5e/utahopera.jpg 480w", "/static/814a3eadcdc58a9da89bb0c790d37015/1a144/utahopera.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/814a3eadcdc58a9da89bb0c790d37015/1a144/utahopera.jpg",
                "alt": "Utah Opera",
                "title": "Utah Opera",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
        <div className="homeButtons">Utah Opera</div>
      </a>
      <a href="https://dvmf.millspub.com" alt="Link to Utah Opera digital publications">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAIBAwQF/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAAB4tjK8gYAaNLrOSOf/8QAGhAAAwEBAQEAAAAAAAAAAAAAAAECEQMSEP/aAAgBAQABBQIhJ10SV4YYevmnonrMjpN//8QAFhEBAQEAAAAAAAAAAAAAAAAAABIB/9oACAEDAQE/AaUjEY//xAAWEQEBAQAAAAAAAAAAAAAAAAAAERL/2gAIAQIBAT8BRWn/xAAcEAACAQUBAAAAAAAAAAAAAAAAARECITAxUXH/2gAIAQEABj8CIbS9IpcruDVxn//EABsQAAMBAQADAAAAAAAAAAAAAAABETFBECFR/9oACAEBAAE/IZCabNwNyKuO+DC9RfSKnwkSVHeqZU9GlNZ//9oADAMBAAIAAwAAABC/6Q3/xAAVEQEBAAAAAAAAAAAAAAAAAAABAP/aAAgBAwEBPxADJhmY/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERIf/aAAgBAgEBPxCFDlZokP/EAB4QAAIDAAEFAAAAAAAAAAAAAAERACExYUFRcYGh/9oACAEBAAE/ECZIxoELdDyogUSAhQP64RPSDAz3Bw6ruIMhVqGTsMiYx+RLBwwpAdcCEEyxP//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/0a3329510c81b794617532e814c71d45/8ac56/dvmf.webp 240w", "/static/0a3329510c81b794617532e814c71d45/d3be9/dvmf.webp 480w", "/static/0a3329510c81b794617532e814c71d45/87ca7/dvmf.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/0a3329510c81b794617532e814c71d45/09b79/dvmf.jpg 240w", "/static/0a3329510c81b794617532e814c71d45/7cc5e/dvmf.jpg 480w", "/static/0a3329510c81b794617532e814c71d45/1a144/dvmf.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/0a3329510c81b794617532e814c71d45/1a144/dvmf.jpg",
                "alt": "Deer Valley® Music Festival",
                "title": "Deer Valley® Music Festival",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
        <div className="homeButtons">Deer Valley<sup>®</sup> Music Festival</div>
      </a>
    </section>
    <blockquote>
      <p parentName="blockquote">{`🔇 We appreciate you silencing your phone.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      